<template>
  <div
    v-if="userFarm !== undefined"
    class="p-6 w-full flex flex-wrap items-center"
  >
    <Checkbox
      :error="errorCode === 'emailNotValid' ? true : false"
      errorMessage="Ugyldig e-mail"
      icon="fa-building"
      label="Flere Driftsenheder"
      class="w-1/2 pr-3"
      v-model="userFarm.multipleUnits"
      @change="updateUsingMultipleUnits(userFarm.multipleUnits)"
    />
    <Button
      v-if="userFarm.multipleUnits"
      label="Tilføj enhed"
      icon="fa-plus"
      class="bg-yellow w-48 ml-3"
      @click="unitModal = true"
    />
  </div>
  <div
    v-if="userFarm !== undefined && unitsList.length !== 0"
    class="flex flex-col w-full"
  >
    <div
      v-if="userFarm.multipleUnits"
      class="flex w-full text-green text-2xl border-b-2 border-green pl-6"
    >
      <span class="flex flex-shrink-0 justify-center items-center">
        <i class="fas fa-building"></i>
      </span>
      <p class="pl-6 font-bold">{{ unitsList[0].data().unitName }}</p>
    </div>
    <div v-if="userFarm.multipleUnits" class="p-6 items-center flex flex-wrap">
      <Checkbox
        :error="errorCode === 'emailNotValid' ? true : false"
        errorMessage="Ugyldig e-mail"
        label="Ejer registrer tid"
        class="w-full mb-6"
        v-model="unitsDataList[0].ownerRegistersTime"
        @change="updateOwnerRegistersTime(unitsList[0])"
      />
      <div class="w-full flex flex-col mb-6">
        <Checkbox
          :error="errorCode === 'emailNotValid' ? true : false"
          errorMessage="Ugyldig e-mail"
          icon="fa-wifi"
          label="Wifi Tjek"
          class="w-full mb-3"
          v-model="unitsDataList[0].usingIp"
          @change="updateUsingIp(unitsList[0])"
        />

        <div v-if="unitsDataList[0].usingIp" class="w-full flex items-center">
          <span
            class="flex flex-shrink-0 items-center text-2xl w-1/6"
            :class="[wifiValid(unitsList[0]) ? 'text-green' : 'text-red-500']"
          >
            <i class="fas fa-wifi"></i>
          </span>
          <p v-if="!wifiValid(unitsList[0])" class="w-3/6">
            Dit nuværende wifi er ikke registreret som værende gyldigt.
          </p>
          <p v-if="wifiValid(unitsList[0])" class="w-3/6">
            Dit nuværende wifi er registreret som værende gyldigt.
          </p>
          <Button
            v-if="unitsDataList[0].usingIp"
            label="Registrer wifi"
            icon="fa-sync"
            class="bg-yellow w-2/6 mt-3"
            @click="submitUpdateIpAddress(unitsList[0])"
          />
        </div>
      </div>

      <div class="w-full flex flex-col">
        <Checkbox
          :error="errorCode === 'emailNotValid' ? true : false"
          errorMessage="Ugyldig e-mail"
          icon="fa-map-marked-alt"
          label="Gps Tjek"
          class="w-full mb-3"
          v-model="unitsDataList[0].usingGps"
          @change="updateUsingGps(unitsList[0])"
        />

        <div
          v-if="unitsDataList[0].usingGps"
          class="w-full flex flex-wrap items-center"
        >
          <div
            :id="'map' + unitsList[0].id"
            class="h-72 w-full border-2 border-green rounded-md"
          ></div>
        </div>
      </div>
      <!-- <div class="w-full flex flex-col">
        <Checkbox
          :error="errorCode === 'emailNotValid' ? true : false"
          errorMessage="Ugyldig e-mail"
          icon="fa-map-marked-alt"
          label="Gps Log"
          class="w-full mb-3"
          v-model="unitsDataList[0].usingGpsLog"
          @change="updateUsingGpsLog(unitsList[0])"
        />

        <div
          v-if="unitsDataList[0].usingGps"
          class="w-full flex flex-wrap items-center"
        >
          <div
            :id="'map' + unitsList[0].id"
            class="h-72 w-full border-2 border-green rounded-md"
          ></div>
        </div>
      </div> -->
    </div>
    <template v-if="unitsList.length > 1 && userFarm.multipleUnits">
      <template v-for="(unit, idx) in unitsDataList.slice(1)" :key="unit.id">
        <div
          class="flex w-full text-green text-2xl border-b-2 border-green pl-6 "
        >
          <span class="flex flex-shrink-0 justify-center items-center">
            <i class="fas fa-building"></i>
          </span>
          <p class="font-bold pl-6">{{ unit.unitName }}</p>
        </div>
        <div class="p-6 items-center flex flex-wrap">
          <Checkbox
            :error="errorCode === 'emailNotValid' ? true : false"
            errorMessage="Ugyldig e-mail"
            label="Ejer registrer tid"
            class="w-full mb-6"
            v-model="unit.ownerRegistersTime"
            @change="updateOwnerRegistersTime(unitsList[idx + 1])"
          />
          <div class="w-full flex flex-col mb-6">
            <Checkbox
              :error="errorCode === 'emailNotValid' ? true : false"
              errorMessage="Ugyldig e-mail"
              icon="fa-wifi"
              label="Wifi Tjek"
              class="w-full mb-3"
              v-model="unit.usingIp"
              @change="updateUsingIp(unitsList[idx + 1])"
            />

            <div v-if="unit.usingIp" class="w-full flex items-center">
              <span
                class="flex flex-shrink-0 items-center text-2xl w-1/6"
                :class="[
                  wifiValid(unitsList[idx + 1]) ? 'text-green' : 'text-red-500'
                ]"
              >
                <i class="fas fa-wifi"></i>
              </span>
              <p v-if="!wifiValid(unitsList[idx + 1])" class="w-3/6">
                Dit nuværende wifi er ikke registreret som værende gyldigt.
              </p>
              <p v-if="wifiValid(unitsList[idx + 1])" class="w-3/6">
                Dit nuværende wifi er registreret som værende gyldigt.
              </p>
              <Button
                v-if="unit.usingIp"
                label="Registrer wifi"
                icon="fa-sync"
                class="bg-yellow w-2/6 mt-3"
                @click="submitUpdateIpAddress(unitsList[idx + 1])"
              />
            </div>
          </div>

          <div class="w-full flex flex-col">
            <Checkbox
              :error="errorCode === 'emailNotValid' ? true : false"
              errorMessage="Ugyldig e-mail"
              icon="fa-map-marked-alt"
              label="Gps Tjek"
              class="w-full mb-3"
              v-model="unit.usingGps"
              @change="updateUsingGps(unitsList[idx + 1])"
            />

            <div
              v-if="unit.usingGps"
              class="w-full flex flex-wrap items-center"
            >
              <div
                :id="'map' + unitsList[idx + 1].id"
                class="h-72 w-full border-4 border-green rounded-md flex items-center justify-center"
              >
                <p class="text-green">Henter kort. . .</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
  <Modal @closeModal="unitModal = null" :visible="unitModal">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-building"></i>
    </span>
    <p class="text-lg font-semibold">
      Tilføj en ny driftsenhed.
    </p>
    <form
      class="w-full md:w-3/4 flex flex-col items-center justify-center my-3 md:my-6"
    >
      <Input
        :error="errorCode === 'emailNotValid' ? true : false"
        errorMessage="Ugyldig e-mail"
        label="Enhedens navn"
        class="w-full pr-3"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        placeholder=""
        v-model="newUnit.unitName"
      />
    </form>
    <Button
      class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
      label="Tilføj enhed"
      @click="submitNewUnit"
    />
  </Modal>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { farm } from "@/global/services/farm";
import { units } from "@/global/services/units";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import Checkbox from "@/components/Checkbox.vue";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { errorLogging } from "@/global/services/errorLogging";
import ipify from "ipify2";

export default {
  name: "TimeRegistrationSettings",
  components: { Input, Checkbox, Button, Modal },
  setup() {
    const state = {
      errorCode: ref(""),
      newUnit: ref({}),
      unitModal: ref(false),
      unitsList: units.getUnitsList(true),
      unitsDataList: units.getUnitsDataList(true),
      userFarm: farm.getFarm(true),
      localPubIp: ref("")
    };

    ipify
      .ipv4()
      .then(ipv4 => (state.localPubIp.value = ipv4))
      .catch(() =>
        errorLogging.setWarning("Add-blocker forhindrer brug af Wifi Tjek")
      );

    const submitUpdateIpAddress = unit => {
      ipify
        .ipv4()
        .then(ipv4 => units.updateIpAddress(unit, ipv4))
        .catch(err => errorLogging.setError(err));
    };

    const wifiValid = unit => {
      let result = null;
      if (unit.data().ipAddress === state.localPubIp.value) {
        result = true;
      } else {
        result = false;
      }
      return result;
    };

    watch(state.unitModal.value, () => {
      if (!state.unitModal.value) {
        state.newUnit.value = {};
      }
    });

    const submitNewUnit = () => {
      const event1 = new CustomEvent("processing", { detail: true });
      document.dispatchEvent(event1);
      units
        .createUnit(state.newUnit.value)
        .then(message => {
          state.unitModal.value = false;
          const event2 = new CustomEvent("processing", { detail: false });
          document.dispatchEvent(event2);
          errorLogging.setSuccess(message);
        })
        .catch(message => {
          errorLogging.setError(message);
          const event2 = new CustomEvent("processing", { detail: false });
          document.dispatchEvent(event2);
        });
    };
    onMounted(() => {
      watch(
        state.unitsList.value,
        async () => {
          if (state.unitsList.value.length !== 0) {
            state.unitsList.value.forEach(unit => {
              if (unit.data().usingGps) {
                navigator.geolocation.getCurrentPosition(
                  geo => {
                    mapboxgl.accessToken =
                      "pk.eyJ1IjoiZW1pZWxqYiIsImEiOiJja2xjcGNiZG8wajRjMndtanZ0OWlvbnJuIn0.ye9DfLEqitSFvNVQewzczg";
                    let map;
                    if (unit.data().gpsBounds1) {
                      const drawnBounds1 = unit.data().gpsBounds1;
                      const drawnBounds2 = unit.data().gpsBounds2;
                      const drawnBounds = [
                        (drawnBounds1[0] + drawnBounds2[0]) / 2,
                        (drawnBounds1[1] + drawnBounds2[1]) / 2
                      ];
                      map = L.map("map" + unit.id).setView(drawnBounds, 16);
                    } else {
                      map = L.map("map" + unit.id).setView(
                        [geo.coords.latitude, geo.coords.longitude],
                        16
                      );
                    }

                    const baselayers = {
                      "Street map": L.tileLayer(
                        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
                        {
                          attribution:
                            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                          maxZoom: 18,
                          id: "mapbox/streets-v11",
                          tileSize: 512,
                          zoomOffset: -1,
                          accessToken:
                            "pk.eyJ1IjoiZW1pZWxqYiIsImEiOiJja2xjcGNiZG8wajRjMndtanZ0OWlvbnJuIn0.ye9DfLEqitSFvNVQewzczg"
                        }
                      ),
                      "Satellite map": L.tileLayer(
                        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
                        {
                          attribution:
                            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                          maxZoom: 18,
                          id: "mapbox/satellite-v9",
                          tileSize: 512,
                          zoomOffset: -1,
                          accessToken:
                            "pk.eyJ1IjoiZW1pZWxqYiIsImEiOiJja2xjcGNiZG8wajRjMndtanZ0OWlvbnJuIn0.ye9DfLEqitSFvNVQewzczg"
                        }
                      )
                    };

                    const overlays = {};

                    L.control.layers(baselayers, overlays).addTo(map);

                    baselayers["Street map"].addTo(map);

                    // FeatureGroup is to store editable layers
                    const drawnItems = new L.FeatureGroup();
                    map.addLayer(drawnItems);
                    const drawControl = new L.Control.Draw({
                      draw: {
                        circlemarker: false,
                        circle: false,
                        polyline: false,
                        polygon: false,
                        marker: false
                      },
                      edit: {
                        remove: true,
                        featureGroup: drawnItems
                      }
                    });

                    map.addControl(drawControl);
                    map.on(L.Draw.Event.CREATED, function(e) {
                      const corners = e.layer.getLatLngs();
                      const bounds = [
                        [corners[0][0].lat, corners[0][0].lng],
                        [corners[0][2].lat, corners[0][2].lng]
                      ];
                      units.updateGpsCoordinates(unit, bounds[0], bounds[1]);
                      const layer = e.layer;
                      // Do whatever else you need to. (save to db; add to map etc)
                      map.addLayer(layer);
                    });
                    if (unit.data().gpsBounds1 && unit.data().gpsBounds2) {
                      const Ebounds = [
                        unit.data().gpsBounds1,
                        unit.data().gpsBounds2
                      ];
                      const Erectangle = L.rectangle(Ebounds).addTo(map);
                    }
                  },
                  undefined,
                  {
                    enableHighAccuracy: true
                  }
                );
              }
            });
          }
        },
        {
          immediate: true
        }
      );
    });

    return {
      ...state,
      updateOwnerRegistersTime: units.updateOwnerRegistersTime,
      updateUsingMultipleUnits: farm.updateUsingMultipleUnits,
      submitNewUnit,
      updateUsingIp: units.updateUsingIp,
      updateUsingGps: units.updateUsingGps,
      updateUsingGpsLog: units.updateUsingGpsLog,
      submitUpdateIpAddress,
      wifiValid
    };
  }
};
</script>
